// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-template-image-jsx": () => import("./../src/pages/template/image.jsx" /* webpackChunkName: "component---src-pages-template-image-jsx" */),
  "component---src-pages-template-news-jsx": () => import("./../src/pages/template/news.jsx" /* webpackChunkName: "component---src-pages-template-news-jsx" */),
  "component---src-pages-template-notification-jsx": () => import("./../src/pages/template/notification.jsx" /* webpackChunkName: "component---src-pages-template-notification-jsx" */),
  "component---src-pages-template-quote-jsx": () => import("./../src/pages/template/quote.jsx" /* webpackChunkName: "component---src-pages-template-quote-jsx" */),
  "component---src-pages-template-quote-with-image-jsx": () => import("./../src/pages/template/quoteWithImage.jsx" /* webpackChunkName: "component---src-pages-template-quote-with-image-jsx" */)
}

